'use client';
import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { AsProp } from 'src/@types/helpers';

type ButtonVariant = 'danger' | 'primary' | 'secondary' | 'default' | 'text' | 'white';
type ButtonType = 'submit' | 'reset' | 'button';
type ButtonSize = 'sm' | 'md' | 'lg';

interface Props extends React.PropsWithChildren<React.ComponentPropsWithoutRef<'button'>>, AsProp {
    variant: ButtonVariant;
    type?: ButtonType;
    size?: ButtonSize;
    loading?: boolean;
}

const variants = {
    secondary: 'btn-secondary',
    primary: 'btn-primary',
    danger: 'btn-danger',
    default: 'btn-default',
    text: 'btn-text',
    white: 'btn-white',
};

const sizes = {
    lg: 'btn-lg',
    md: 'btn-md',
    sm: 'btn-sm',
};

const Button = React.forwardRef<HTMLElement, Props>(
    (
        { as: Component = 'button', variant, type = 'button', size = 'sm', children, className = '', loading, ...rest },
        ref,
    ) => {
        const cn = `btn ${variants[variant]} ${sizes[size]} ${className}`.trim();

        return (
            <Component ref={ref} type={Component === 'button' ? type : undefined} {...rest} className={cn}>
                {loading && <AiOutlineLoading3Quarters className="-mt-1 mr-2 inline animate-spin" />}
                {children}
            </Component>
        );
    },
);

Button.displayName = 'Button';

export default Button;
