export const createCookie = (name: string, value: string, days: number) => {
    if (!window) {
        return;
    }
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    // const domain = window.location.hostname === 'localhost' ? 'localhost' : 'bioclinica.ro';
    const domain = window.location.hostname;
    window.document.cookie = `${name}=${value}${expires}; path=/; domain=${domain}`;
};

export const readCookie = (name: string) => {
    if (typeof window === 'undefined' || !window || !window.document) {
        return null;
    }
    let nameEQ = `${name}=`;
    let ca = window.document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

export const deleteCookie = (name: string, domain: string) => {
    if (window) {
        window.document.cookie = `${name}=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
};
