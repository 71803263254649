'use client';

import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { Disclosure } from '@headlessui/react';

import Button from './Button';
import Modal from './Modal';
import Switch from './Switch';
import { createCookie, deleteCookie, readCookie } from 'src/services/cookies';

const COOKIECONSENT_STATUS = 'cookieconsent_status';
const COOKIECONSENT_PREFERENCES = 'cookieconsent_preferences';
const DEFAULT_PREFERENCES = ['analytics'];

type Props = {
    withBanner?: boolean;
    withButton?: boolean;
};

const getUserPreferences = () => {
    const preferences = readCookie(COOKIECONSENT_PREFERENCES);
    try {
        return preferences ? JSON.parse(preferences) : null;
    } catch (e) {
        return null;
    }
};

const settings = {
    id: 'cookieconsent',
    class: '',
    backdrop: true,
    baseUrl: '',
    message: 'Mesajul despre cookie-uri...',
    expireDays: 365,
    modalTitle: 'Preferințe cookie-uri',
    bannerText: (
        <>
            Cookie-urile sunt stocate pentru a optimiza site-ul nostru, pentru a colecta informații despre modul în care
            interacționați cu noi și a vă personaliza experiența de navigare. Aflați mai multe detalii citind{' '}
            <Link href="/politica-privind-cookies">Politica privind Cookies</Link>
        </>
    ),
    modalText: (
        <>
            <p>
                Acest site web folosește cookie-uri pentru a vă îmbunătăți experiența în timp ce navigați pe site. Din
                aceste cookie-uri, cookie-urile care sunt clasificate ca fiind necesare sunt stocate pe browser-ul dvs.
                deoarece sunt esențiale pentru funcționarea funcționalităților de bază ale site-ului.
            </p>
            <p>
                De asemenea, folosim cookie-uri terțe care ne ajută să analizăm și să înțelegem cum utilizați acest site
                web, pentru a stoca preferințele utilizatorilor și pentru a vă oferi conținut și reclame relevante.
                Aceste cookie-uri vor fi stocate pe browserul dvs., dar numai după obținerea consimțământului.
            </p>
            <p>
                Veți avea, de asemenea, opțiunea de a renunța la aceste cookie-uri dacă doriți. Dar renunțarea la unele
                dintre aceste cookie-uri poate avea un efect asupra experienței dvs. de navigare, conform descrierilor
                elucidate împotriva categoriilor respective de mai jos.
            </p>
        </>
    ),
    acceptButtonLabel: 'Acceptă',
    customizeButtonLabel: 'Setări cookies',
    customizeFixedLabel: 'Activ',
    customizeCookiesToSelect: [
        {
            name: 'necesary',
            title: 'Cookie-uri necesare',
            description:
                'Aceste cookie-uri sunt obligatorii pentru funcționarea corectă a site-ului și ele nu pot fi dezactivate. Continuarea folosirii site-ului reprezintă acceptul dvs. de a folosi aceste cookie-uri.',
            isFixed: true,
            options: [
                {
                    name: 'this.sid',
                    description:
                        'Cookie-ul este utilizat pentru stocarea și identificarea ID-ului de sesiune unic al utilizatorului. Acest lucru ne permite să ținem minte între pagini ce analize ați adăugat în coș.',
                },
                {
                    name: 'language',
                    description: 'Salvează limba în care este configurat browser-ul dvs.',
                },
                {
                    name: 'connect.sid',
                    description:
                        'Cookie-ul este utilizat pentru autentificarea utilizatorului în secțiunea Contul Meu Bioclinica.',
                },
                {
                    name: COOKIECONSENT_STATUS,
                    description:
                        'Salvează dacă s-a acceptat politica de cookie-uri a site-ului. Asta ne permite să nu mai afișăm banner-ul de cookie-uri la o vizită ulterioară pe site-ul Bioclinica.',
                },
                {
                    name: COOKIECONSENT_PREFERENCES,
                    description:
                        'Salvează preferințele din acestă fereastră pentru a nu vă pune în situația de a face reconfigurarea la o vizită recurentă a site-ului Bioclinica.',
                },
            ],
        },
        {
            name: 'analytics',
            title: 'Analytics',
            description:
                'Cookie-urile Analytics ne ajută să înțelegem cum interacționează vizitatorii noștri cu site-ul. Ne ajută să înțelegem numărul de vizitatori, de unde provin vizitatorii și paginile pe care le navighează. Cookie-urile colectează aceste date și sunt raportate anonim.',
            isFixed: false,
            options: [
                {
                    name: '_ga, _gid, _gat',
                    description:
                        'Cookie-uri folosite de Google Analytics pentru a monitoriza traficul site-ului în mod anonim.',
                },
                {
                    name: '_fbp, fr',
                    description:
                        'Cookie-uri folosite de Facebook Pixel care ne permit să avem acces la statistici legate de modul în care sunt distribuite paginile noastre.',
                },
            ],
        },
    ],
};

function CustomisedOptionsTable({ options }) {
    return (
        <table className="table-fixed">
            <thead>
                <tr>
                    <th scope="col">COOKIE</th>
                    <th scope="col">DESCRIERE</th>
                </tr>
            </thead>
            <tbody>
                {options.map((opt) => (
                    <tr key={opt.name}>
                        <td>
                            <code>{opt.name}</code>
                        </td>
                        <td>{opt.description}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default function CookieConsent({ withBanner, withButton }: Props) {
    const [show, setShow] = useState(false);
    const [ready, setReady] = useState(false);
    const [initialPreferences] = useState<string[]>(getUserPreferences() || DEFAULT_PREFERENCES);
    const [preferences, setPreferences] = useState<string[]>(initialPreferences);

    const modalId = `${settings?.id}-modal`;

    useEffect(() => {
        setReady(true);
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        e?.preventDefault();
        setShow(true);
    };
    const handleAccept = (values?: string[]) => {
        const preferences = values || DEFAULT_PREFERENCES;
        if (preferences?.indexOf('analytics') < 0) {
            deleteCookie('fr', '.facebook.com');
            deleteCookie('_fbp', '.bioclinica.ro');
        }
        createCookie(COOKIECONSENT_PREFERENCES, JSON.stringify(preferences), settings.expireDays);
        createCookie(COOKIECONSENT_STATUS, 'dismiss', settings.expireDays);
        if (window) {
            window.location.reload();
        }
        window?.gtag('consent', 'update', {
            analytics_storage: preferences?.indexOf('analytics') < 0 ? 'denied' : 'granted',
        });
    };
    const handleAcceptModal = () => {
        handleAccept(preferences);
    };

    const handleChange = (v: string, checked: boolean) => {
        return setPreferences([...preferences.filter((p) => p !== v), ...(checked ? [v] : [])]);
    };

    const cookiesSelections = () => {
        return settings.customizeCookiesToSelect.map((field: any, index: number) => {
            let cookieLabel = '';
            let checked = false;
            let disabled = false;

            if (field.isFixed === true) {
                cookieLabel = settings.customizeFixedLabel;
                checked = true;
                disabled = true;
            } else {
                if (preferences.indexOf(field.name) !== -1) {
                    checked = true;
                }
                cookieLabel = preferences.indexOf(field.name) !== -1 ? 'Activ' : 'Inactiv';
            }

            return (
                <Disclosure as="div" key={`${field.name}-${index}`} className="mb-3">
                    <Disclosure.Button as="div" className="flex flex-row justify-between gap-4 bg-white p-4">
                        <div className="flex-1">{field.title}</div>
                        <label>{cookieLabel}</label>
                        <div className="relative">
                            <Switch
                                checked={checked}
                                disabled={disabled}
                                onChange={(checked) => handleChange(field.name, checked)}
                            />
                        </div>
                    </Disclosure.Button>
                    <Disclosure.Panel as="div" className="bg-white p-4">
                        <div className="mb-3">{field.description}</div>
                        <CustomisedOptionsTable options={field.options} />
                    </Disclosure.Panel>
                </Disclosure>
            );
        });
    };

    const cookieStatus = readCookie(COOKIECONSENT_STATUS);
    const cookiePreferences = readCookie(COOKIECONSENT_PREFERENCES);

    if (!ready || (withBanner === true && cookieStatus && cookiePreferences)) {
        return <div />;
    }

    return (
        <>
            {withBanner && (
                <div
                    role="dialog"
                    aria-live="polite"
                    aria-label="cookieconsent"
                    aria-describedby="cookieconsent:desc"
                    className="bg-dark-green/95 sticky bottom-0 z-40"
                    id={`${settings.id}-banner`}>
                    {/*googleoff: all*/}
                    <div className="container mx-auto grid w-full grid-cols-1 gap-4 px-4 pb-10 pt-10 text-sm font-medium md:grid-cols-2 lg:grid-cols-3">
                        <div id="cookieconsent:desc" className="col-span-1 text-white lg:col-span-2">
                            {settings.bannerText}
                        </div>
                        <div className="self-center">
                            <div className="flex justify-center gap-4 md:justify-end">
                                <Button
                                    variant="primary"
                                    aria-label={settings.customizeButtonLabel}
                                    className="btn btn-light btn-sm w-100"
                                    onClick={handleShow}>
                                    {settings.customizeButtonLabel}
                                </Button>
                                <Button
                                    variant="secondary"
                                    aria-label={settings.acceptButtonLabel}
                                    className="btn btn-primary btn-sm w-100"
                                    onClick={() => handleAccept()}>
                                    {settings.acceptButtonLabel}
                                </Button>
                            </div>
                        </div>
                    </div>
                    {/*googleon: all*/}
                </div>
            )}
            {withButton && (
                <Button
                    variant="secondary"
                    aria-label="customizați cookie-urile"
                    className="btn btn-secondary"
                    onClick={handleShow}>
                    {settings.customizeButtonLabel}
                </Button>
            )}

            <Modal
                size="3xl"
                show={show}
                bg="light"
                onClose={handleClose}
                title={
                    <div className="text-primary font-display p-4 pb-0 text-2xl md:px-6">{settings.modalTitle}</div>
                }>
                <div className="p-4 md:p-6">
                    {settings.modalText}
                    <div className="mt-4">{cookiesSelections()}</div>
                    <div className="mt-4 flex justify-center gap-4">
                        <Button id={`${modalId}-save-button`} variant="primary" onClick={handleAcceptModal}>
                            {settings.acceptButtonLabel}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
