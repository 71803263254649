import { ReactNode } from 'react';
import { Label, Switch as HeadlessSwitch, Field, type SwitchProps } from '@headlessui/react';

type Props = {
    label?: ReactNode;
    disabled?: boolean;
} & SwitchProps<any>;

export default function Switch({ label, ...rest }: Props) {
    return (
        <Field>
            <div className="flex items-center">
                <Label className="flex-1">{label}</Label>
                <HeadlessSwitch
                    {...rest}
                    className={`${rest.checked ? 'bg-primary' : 'bg-slate-400'} ${
                        rest.disabled ? 'opacity-60' : ''
                    } focus:ring-outline-green relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-1 focus:ring-offset-1`}>
                    <span
                        className={`${
                            rest.checked ? 'translate-x-6' : 'translate-x-1'
                        } bg-light-grey inline-block h-4 w-4 transform rounded-full transition-transform`}
                    />
                </HeadlessSwitch>
            </div>
        </Field>
    );
}
